.driver-popover.driverjs-theme {
    background-color: #eaf0fe;
    color: #000;
  }
  
  .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
  }
  
  .driver-popover.driverjs-theme .driver-popover-title,
  .driver-popover.driverjs-theme .driver-popover-description,
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    color: #000;
  }
  
  .driver-popover.driverjs-theme button {
    flex: 1;
    text-align: center;
    background-color: #2e6ff3;
    color: #000;
    border: 2px solid #000;
    text-shadow: none;
    font-size: 14px;
    padding: 2px;
    border-radius: 6px;
    margin-left: 20px;
  }
  
  .driver-popover.driverjs-theme button:hover {
    background-color: #2e6ff3;
    color: #ffffff;
  }
  
  .driver-popover.driverjs-theme .driver-popover-navigation-btns {
    display: flex;
    justify-content: flex-end;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn {
    display: none !important;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn:hover {
    color: #2e6ff3;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: #eaf0fe;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: #eaf0fe;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: #eaf0fe;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: #eaf0fe;
  }

  .floating-tour-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  .floating-tour-btn:hover {
    background-color: #0056b3;
  }
  